<template>
  <div
      :class="[!dataLoaded && 'overlay overlay-block {{widgetClasses}}']"
      class="card"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :key="title">{{
            title
          }}</span>
        <span class="text-muted mt-1 fw-bold fs-7">{{ description }}</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <div
          class="overlay-layer rounded bg-white bg-opacity-5"
          :class="[dataLoaded && 'd-none']"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <template v-for="(item, index) in items" :key="index">
        <!--begin::Item-->
        <div
            :class="{ 'mb-7': items.length - 1 !== index }"
            :key="filters"
            v-if="(filters.status === 'All' || item.status.label === filters.status) && (filters.owner === 'All' || item.owner === filters.owner) && (filters.type === 'All' || item.type === filters.type || item.categories[0] === filters.type) && (filters.keyword === '' || item.title.match(new RegExp(filters.keyword, 'i')) || item.sn.match(new RegExp(filters.keyword, 'i')) || item.manufacturer.match(new RegExp(filters.keyword, 'i')) || item.eq_id.match(new RegExp(filters.keyword, 'i')))"
            class="d-flex align-items-sm-center"
        >
          <!--begin::Overlay-->
          <router-link :to="`/assets-list/asset/${item.id}`"  class="d-block overlayxx me-4 min-w-100px" >
            <!--begin::Image-->
            <div class="overlay-wrapperxx bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-100px" v-if="item.photos.length > 0"
                 :style="`background-image: url(${item.photos[0].sm})`">
            </div>
            <div class="wrapperxx bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-100px" v-if="item.photos.length == 0"
                 :style="`background-image: url(media/misc/pattern-1.jpg)`">
            </div>
            <!--end::Image-->

            <!--begin::Action-->
            <div class="overlay-layer card-rounded bg-dark bg-opacity-25 shadow d-none">
              <i class="bi bi-eye-fill text-white fs-3x"></i>
            </div>
            <!--end::Action-->
          </router-link>


          <!--end::Overlay-->
          <!--begin::Title-->
          <div class="d-flex flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal">
            <div class="flex-grow-1 me-2">
              <router-link :to="`/assets-list/asset/${item.id}`"
                  class="text-gray-800 fw-bolder text-hover-primary fs-6"
              >{{ item.title }}</router-link>

              <span class="text-muted fw-bold d-block pt-1">{{ item.category_list }}</span>
              <!--<span class="text-muted fw-bold d-block pt-1">{{ item.location.label }}</span>-->
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Title-->
          <div class="d-flex flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal">
            <div class="flex-grow-1 me-2">
              <span
                  class="text-gray-800 fs-6"
              >{{ item.manufacturer }}</span>

              <span class="text-muted fw-bold d-block pt-1">EQ ID: {{ item.eq_id }}</span>
              <span class="text-muted fw-bold d-block pt-1">SN: {{ item.sn }}</span>
            </div>
          </div>
          <!--end::Title-->

          <!--begin::Chart-->
          <div class="d-flex flex-row-fluid flex-wrap align-items-center my-lg-0 me-2 flex-equal">
            <div class="flex-grow-1">
              <apexchart
                  class="mixed-widget-4-chart"
                  :options="chartOptionsWarning"
                  :series="[`${item.healthScore.value}`]"
                  height="150"
                  type="radialBar"
                  v-if="item.healthScore.class === 'success' && renderApexCharts"
              ></apexchart>
              <!--<apexchart
                  class="mixed-widget-4-chart"
                  :options="chartOptionsDanger"
                  :series="[`${item.healthScore.value}`]"
                  height="150"
                  type="radialBar"
                  v-if="item.healthScore.class === 'danger'"
              ></apexchart>
              <apexchart
                  class="mixed-widget-4-chart"
                  :options="chartOptionsSuccess"
                  :series="[`${item.healthScore.value}`]"
                  height="150"
                  type="radialBar"
                  v-if="item.healthScore.class === 'success'"
              ></apexchart>-->
            </div>
          </div>
          <!--end::Chart-->

          <!--begin::Section-->
          <div class="d-flex align-items-center">
            <div class="me-6">
              <span
                  :class="`badge-light-${item.status.class}`"
                  v-if="item.status.label === 'Online'"
                  class="badge fs-8 fw-bolder my-2">In service</span>
              <span
                  :class="`badge-light-${item.status.class}`"
                  v-if="item.status.label === 'Offline'"
                  class="badge fs-8 fw-bolder my-2">Out of service</span>

            </div>



            <!--begin::Menu-->
            <!--<div class="me-5">
              <button
                  class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                  data-kt-menu-flip="top-end"
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>
              <AssetActionsDropdown v-if="item.status.class"></AssetActionsDropdown>
            </div>-->
            <!--end::Menu-->

            <router-link :to="`/assets-list/asset/${item.id}`" class="btn btn-icon btn-light btn-sm border-0">
                <span class="svg-icon-2 svg-icon-primary">
                  <inline-svg
                      src="media/icons/duotone/Navigation/Arrow-right.svg"
                  />
                </span>
            </router-link>
          </div>
          <!--end::Section-->
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import {inject, defineComponent, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import AssetActionsDropdown from "@/components/dropdown/AssetActionsDropdown.vue";

export default defineComponent({
  name: "AssetsList",
  components: {AssetActionsDropdown},
  props: {
    widgetClasses: String,
    owner: String,
    status: String,
    type: String,
    keyword: String
  },
  setup(props) {
    /*return {
      itemsTabs,
      title,
      description
    };*/

    const renderApexCharts = false;
    const colorWarning = ref("success");

    const baseColorWarning = getCSSVariableValue("--bs-" + colorWarning.value);
    const lightColorWarning = getCSSVariableValue("--bs-light-" + colorWarning.value);
    const labelColorWarning = getCSSVariableValue("--bs-" + colorWarning.value);

    const chartOptionsWarning = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorWarning,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorWarning,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorWarning],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };

    /*const colorDanger = ref("danger");

    const baseColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);
    const lightColorDanger = getCSSVariableValue("--bs-light-" + colorDanger.value);
    const labelColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);

    const chartOptionsDanger = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorDanger,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorDanger,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorDanger],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };



    const colorSuccess = ref("success");

    const baseColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);
    const lightColorSuccess = getCSSVariableValue("--bs-light-" + colorSuccess.value);
    const labelColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);

    const chartOptionsSuccess = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorSuccess,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorSuccess,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorSuccess],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };*/


    return {
      chartOptionsWarning,
      renderApexCharts
      //chartOptionsDanger,
      //chartOptionsSuccess
    };
  },
  data: function () {
    const filters = {owner: "All", status: "All", type: "All", keyword: ""};
    const items = [];
    const title = "Asset management";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function () {
    emitter.on("filterAssetsList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.owner = filterDataFromEvent.owner;
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      this.filters.keyword = filterDataFromEvent.keyword;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/list")
          .then(data => {
            //console.log(data);
            //update project tabs table test
            this.title = "Equipment list";
            this.description = "To view detailed information click on the arrow in the right of the row";
            this.$nextTick(() => {
              this.items = data.data.data;
              this.renderCharts();
              let assetCategory = this.$route.query.type;
              if (assetCategory === "hotwash") {
                this.filters.type = "Hot Wash unit";
              } else if (assetCategory === "jetting") {
                this.filters.type = "Jetting";
              } else if (assetCategory === "compressors") {
                this.filters.type = "Compressor";
              }
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            //console.log(response);
          });
    },
    renderCharts() {
      this.renderApexCharts = true;
    }
  }
});
</script>
