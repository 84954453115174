
import {inject, defineComponent, ref} from "vue";
import ApiService from "@/core/services/ApiService";
import {emitter} from "@/main";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import AssetActionsDropdown from "@/components/dropdown/AssetActionsDropdown.vue";

export default defineComponent({
  name: "AssetsList",
  components: {AssetActionsDropdown},
  props: {
    widgetClasses: String,
    owner: String,
    status: String,
    type: String,
    keyword: String
  },
  setup(props) {
    /*return {
      itemsTabs,
      title,
      description
    };*/

    const renderApexCharts = false;
    const colorWarning = ref("success");

    const baseColorWarning = getCSSVariableValue("--bs-" + colorWarning.value);
    const lightColorWarning = getCSSVariableValue("--bs-light-" + colorWarning.value);
    const labelColorWarning = getCSSVariableValue("--bs-" + colorWarning.value);

    const chartOptionsWarning = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorWarning,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorWarning,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorWarning],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };

    /*const colorDanger = ref("danger");

    const baseColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);
    const lightColorDanger = getCSSVariableValue("--bs-light-" + colorDanger.value);
    const labelColorDanger = getCSSVariableValue("--bs-" + colorDanger.value);

    const chartOptionsDanger = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorDanger,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorDanger,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorDanger],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };



    const colorSuccess = ref("success");

    const baseColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);
    const lightColorSuccess = getCSSVariableValue("--bs-light-" + colorSuccess.value);
    const labelColorSuccess = getCSSVariableValue("--bs-" + colorSuccess.value);

    const chartOptionsSuccess = {
      chart: {
        fontFamily: "inherit",
        height: 50,
        type: "radialBar"
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "65%"
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: false,
              fontWeight: "700"
            },
            value: {
              color: labelColorSuccess,
              fontSize: "24px",
              fontWeight: "700",
              offsetY: 8,
              show: true,
              formatter: function (val) {
                return val + "%";
              }
            }
          },
          track: {
            background: lightColorSuccess,
            strokeWidth: "100%"
          }
        }
      },
      colors: [baseColorSuccess],
      stroke: {
        lineCap: "round"
      },
      labels: ["Progress"]
    };*/


    return {
      chartOptionsWarning,
      renderApexCharts
      //chartOptionsDanger,
      //chartOptionsSuccess
    };
  },
  data: function () {
    const filters = {owner: "All", status: "All", type: "All", keyword: ""};
    const items = [];
    const title = "Asset management";
    const description = "";
    const dataLoaded = false;
    return {
      items,
      title,
      filters,
      dataLoaded,
      description
    };
  },
  created: function () {
    emitter.on("filterAssetsList", (data) => {
      const filterDataFromEvent = JSON.parse(JSON.stringify(data));
      this.filters.owner = filterDataFromEvent.owner;
      this.filters.status = filterDataFromEvent.status;
      this.filters.type = filterDataFromEvent.type;
      this.filters.keyword = filterDataFromEvent.keyword;
      //console.log(filterDataFromEvent.status);
      //  this.filterTable();
    });
    setTimeout(() => {
      this.loadAJAXData();
    }, 100);
    /*setInterval(() =>{
      this.loadAJAXData();
    }, 4000);*/
  },
  methods: {
    /*filterTable() {
      this.items = this.items.filter((item) => {
        if (this.filters.status === "All" && this.filters.type === "All") {
          return true;
        } else if (this.filters.status === "All" && this.filters.type !== "All") {
          return item.type === this.filters.type;
        } else if (this.filters.status !== "All" && this.filters.type === "All") {
          return item.status === this.filters.status;
        } else {
          return item.status === this.filters.status && item.type === this.filters.type;
        }
      })
    },*/
    loadAJAXData() {
      ApiService.setHeader();
      ApiService.get("https://cmms.onecorp.cloud/api/v2/assets/list")
          .then(data => {
            //console.log(data);
            //update project tabs table test
            this.title = "Equipment list";
            this.description = "To view detailed information click on the arrow in the right of the row";
            this.$nextTick(() => {
              this.items = data.data.data;
              this.renderCharts();
              let assetCategory = this.$route.query.type;
              if (assetCategory === "hotwash") {
                this.filters.type = "Hot Wash unit";
              } else if (assetCategory === "jetting") {
                this.filters.type = "Jetting";
              } else if (assetCategory === "compressors") {
                this.filters.type = "Compressor";
              }
            });
            this.dataLoaded = true;
          })
          .catch(response => {
            //console.log(response);
          });
    },
    renderCharts() {
      this.renderApexCharts = true;
    }
  }
});
