
import { defineComponent, onMounted } from "vue";
import {MenuComponent} from "@/assets/ts/components";

export default defineComponent({
  name: "asset-actions-dropdown",
  // eslint-disable-next-line vue/no-unused-components
  components: {},
  setup() {
    onMounted(() => {
      MenuComponent.createInstances('.asset-actions-dropdown-wrapper')
    });
  }
});
