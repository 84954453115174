<template>
  <!--begin::Assets List-->
  <div class="row g-5 gx-xxl-12">
      <div class="col-4">
        <router-link :to="`/assets-list/?type=compressors`"  class="d-block overlayxx me-4 min-w-100px" >
          <div class="card-xl-stretch mb-5 mb-xl-12 card">
            <div class="card-header border-0 py-5 d-block">
              <h3 class="card-title align-items-center flex-column">
                <span class="card-label fw-bolder fs-3 mb-1 me-0 text-center">Compressors</span>
              </h3>
            </div>
            <div class="card-body d-flex flex-column" style="max-height: 200px; overflow: hidden; padding-top: 0px; margin-bottom: 30px">
                <!--begin::Image-->
                <div class="overlay-wrapperxx bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-200px"
                     :style="`background-image: url(https://cmms.onecorp.cloud/site/assets/files/14133/mvimg_20210616_104402.200x0.jpg)`">
                </div>
                <!--end::Image-->
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-4">
        <router-link :to="`/assets-list/?type=jetting`"  class="d-block overlayxx me-4 min-w-100px" >
          <div class="card-xl-stretch mb-5 mb-xl-12 card">
            <div class="card-header border-0 py-5 d-block">
              <h3 class="card-title align-items-center flex-column">
                <span class="card-label fw-bolder fs-3 mb-1 me-0 text-center">Jetting units</span>
              </h3>
            </div>
            <div class="card-body d-flex flex-column" style="max-height: 200px; overflow: hidden; padding-top: 0px; margin-bottom: 30px">
              <!--begin::Image-->
              <div class="overlay-wrapperxx bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-200px"
                   :style="`background-image: url(https://cmms.onecorp.cloud/site/assets/files/14113/mvimg_20210616_105454-1.200x0.jpg)`">
              </div>
              <!--end::Image-->
            </div>
          </div>
        </router-link>
      </div>
      <div class="col-4">
        <router-link :to="`/assets-list/?type=hotwash`"  class="d-block overlayxx me-4 min-w-100px" >
          <div class="card-xl-stretch mb-5 mb-xl-12 card">
            <div class="card-header border-0 py-5 d-block">
              <h3 class="card-title align-items-center flex-column">
                <span class="card-label fw-bolder fs-3 mb-1 me-0 text-center">Hot wash units</span>
              </h3>
            </div>
            <div class="card-body d-flex flex-column" style="max-height: 200px; overflow: hidden; padding-top: 0px; margin-bottom: 30px">
              <!--begin::Image-->
              <div class="overlay-wrapperxx bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-200px"
                   :style="`background-image: url(https://cmms.onecorp.cloud/site/assets/files/14136/img_20201029_143451.200x0.jpg)`">
              </div>
              <!--end::Image-->
            </div>
          </div>
        </router-link>
      </div>
  </div>
  <!--end::Assets List-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import AssetsList from "@/components/widgets/tables/AssetsList.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";

export default defineComponent({
  name: "assetsListPage",
  components: {
    AssetsList
  },
  methods: {
    filterAssetsListTable(options) {
      this.status = options.status;
      this.customer = options.customer;
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Asset categories");
      setCurrentPageBreadcrumbs("Asset categories", ["Asset management"]);
      setCurrentPageToolbarType("assetsCategories");
    });
  },
  data() {
    const customer = "All";
    const status = "All";
    return {
      customer,
      status
    }
  }
});
</script>
