<template>
  <!--begin::Asset actions-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3 asset-actions-dropdown-wrapper"
    data-kt-menu="true"
  >
    <!--begin::Heading-->
    <div class="menu-item px-3">
      <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
        Actions
      </div>
    </div>
    <!--end::Heading-->
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <a href="#" class="menu-link flex-stack px-3">
        Transfer

        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Transfer asset between locations"
        ></i>
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <a href="#" class="menu-link flex-stack px-3 d-none">
        Revaluation

        <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Revaluation of asset"
        ></i>
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-3 d-none">
      <a href="#" class="menu-link flex-stack px-3">
        Depreciation

        <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Depreciation of asset"
        ></i>
      </a>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <a href="#" class="menu-link flex-stack px-3">
        Disposal

        <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Disposal of asset"
        ></i>
      </a>
    </div>
    <!--end::Menu item-->

  </div>
  <!--end::Asset actions-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import {MenuComponent} from "@/assets/ts/components";

export default defineComponent({
  name: "asset-actions-dropdown",
  // eslint-disable-next-line vue/no-unused-components
  components: {},
  setup() {
    onMounted(() => {
      MenuComponent.createInstances('.asset-actions-dropdown-wrapper')
    });
  }
});
</script>
